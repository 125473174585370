import React, { useState } from "react";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("POS");

  const posItems = [
    "Efficient billing system",
    "Inventory tracking",
    "Real-time analytics",
    "Customer loyalty program integration",
    "Applying for Jobs",
    "Workforce Management",
    "Stock Management",
    "Online Orders",
    "Live Sales Tracking",
    "Squared Transactions",
    "Queue Skipper AI Integration",
  ];

  const kioskItems = [
    "Self-checkout functionality",
    "User-friendly touchscreen interface",
    "Contactless payment options",
    "Customizable branding",
    "Real-time user data tracking",
    "Kiosk-centric inventory syncing",
    "Interactive on-screen promotions",
  ];

  return (
    <section id="tabs" style={{ padding: "2rem", background: "#F3F1EF" }}>
      <div style={{ textAlign: "center", marginBottom: "1rem" }}>
        <button
          onClick={() => setActiveTab("POS")}
          style={{
            padding: "1rem",
            background: activeTab === "POS" ? "#2959A5" : "#FFFFFF",
            color: activeTab === "POS" ? "#FFFFFF" : "#2959A5",
            border: "1px solid #2959A5",
            marginRight: "1rem",
            cursor: "pointer",
          }}
        >
          Queue Skipper POS
        </button>
        <button
          onClick={() => setActiveTab("Kiosk")}
          style={{
            padding: "1rem",
            background: activeTab === "Kiosk" ? "#2959A5" : "#FFFFFF",
            color: activeTab === "Kiosk" ? "#FFFFFF" : "#2959A5",
            border: "1px solid #2959A5",
            cursor: "pointer",
          }}
        >
          Queue Skipper Kiosk
        </button>
      </div>
      <div>
        <h2 style={{ textAlign: "center", color: "#2959A5" }}>
          {activeTab === "POS" ? "POS Features" : "Kiosk Features"}
        </h2>
        <ul style={{ maxWidth: "800px", margin: "0 auto", textAlign: "left" }}>
          {(activeTab === "POS" ? posItems : kioskItems).map((item, index) => (
            <li key={index} style={{ marginBottom: "1rem", color: "#333" }}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Tabs;
