import React from "react";
import { Link } from "react-scroll";

const Header = () => {
  return (
    <header style={{ background: "#2959A5", padding: "1rem", color: "#FFF" }}>
      <nav style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
        <h1>Queue Skipper</h1>
        <div>
          <Link to="hero" smooth={true} duration={500} style={{ color: "#FFF", margin: "0 1rem", cursor: "pointer" }}>
            Home
          </Link>
          <Link to="features" smooth={true} duration={500} style={{ color: "#FFF", margin: "0 1rem", cursor: "pointer" }}>
            Features
          </Link>
          <Link to="pricing" smooth={true} duration={500} style={{ color: "#FFF", margin: "0 1rem", cursor: "pointer" }}>
            Pricing
          </Link>
          <Link to="contact" smooth={true} duration={500} style={{ color: "#FFF", margin: "0 1rem", cursor: "pointer" }}>
            Contact
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;
