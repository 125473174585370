import React from "react";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/contact");
  };

  return (
    <section
      id="hero"
      style={{
        background: "linear-gradient(135deg, #2959A5, #EF3E6D)",
        color: "#FFF",
        padding: "4rem",
        textAlign: "center",
      }}
    >
      <h1>Skip the Queue, Maximize Efficiency</h1>
      <p>Empower your business with Queue Skipper's POS, Kiosk, and Web Portal solutions.</p>
      <button
        onClick={handleGetStarted}
        style={{
          background: "#F3F1EF",
          color: "#EF3E6D",
          padding: "1rem 2rem",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "18px",
        }}
      >
        Get Started
      </button>
    </section>
  );
};

export default HeroSection;
