import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header.js";
import HeroSection from "./components/HeroSection.js";
import Tabs from "./components/Tabs.js";
import Features from "./components/Features.js";
import Pricing from "./components/Pricing.js";
import ContactForm from "./components/ContactForm.js";
// import {About} from "./components/About";

function App() {
  return (
    <Router>
      <div style={{ fontFamily: "'Roboto', sans-serif" }}>
        <Header />
        <HeroSection />
        <Tabs />
        <Features />
        <Pricing />
        {/* <About /> */}
        <ContactForm />
      </div>
    </Router>
  );
}

export default App;
