import React, { useState } from "react";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [interest, setInterest] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !phone || !company || !interest || !message) {
      alert("All fields are required!");
      return;
    }

    setIsLoading(true);

    // Email message content
    const emailMessage = `
      <table style='width:100%;'>
        <tr><td><b>Name:</b></td><td>${name}</td></tr>
        <tr><td><b>Email:</b></td><td>${email}</td></tr>
        <tr><td><b>Phone:</b></td><td>${phone}</td></tr>
        <tr><td><b>Company Name:</b></td><td>${company}</td></tr>
        <tr><td><b>Interest:</b></td><td>${interest}</td></tr>
        <tr><td><b>Message:</b></td><td>${message}</td></tr>
      </table>
    `;

    const emailPayload = {
        key: "KqogytxmJIhoq_Cd3Wxqmg",
        template_name: "v-2-2020-chatime-brand",
        template_content: [
          {
            name: "YOUR_MERGETAG",
            content: "<p>Testing</p>",
          },
        ],
        message: {
          html: "<p>Example HTML content</p>",
          text: "Example text content",
          subject: "Contact Form Submission",
          from_email: "noreply@wherethetruck.at",
          from_name: "Queue Skipper Contact",
          to: [
            {
              email: "frank@amfvg.com.au",
              name: "Frank",
              type: "to",
            },
          ],
          headers: {
            "Reply-To": "noreply@wherethetruck.at",
          },
          merge: true,
          merge_language: "mailchimp",
          global_merge_vars: [
            {
              name: "YOUR_MERGETAG",
              content: emailMessage,
            },
          ],
        },
      };
  

    try {
      const response = await fetch(
        "https://mandrillapp.com/api/1.0/messages/send-template.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailPayload),
        }
      );

      const result = await response.json();

      if (response.ok) {
        alert("Thank you for reaching out! We'll get back to you soon.");
        setName("");
        setEmail("");
        setPhone("");
        setCompany("");
        setInterest("");
        setMessage("");
      } else {
        console.error("Error sending email:", result);
        alert("Failed to send the message. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section id="contact" style={styles.container}>
      <h2 style={styles.header}>Contact Us</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <label>Your Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Your Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Your Phone:</label>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Company Name:</label>
          <input
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Are you interested in POS or Kiosk?</label>
          <select
            value={interest}
            onChange={(e) => setInterest(e.target.value)}
            style={styles.select}
          >
            <option value="">Select an option</option>
            <option value="POS">POS</option>
            <option value="Kiosk">Kiosk</option>
            <option value="Both">Both</option>
          </select>
        </div>
        <div style={styles.inputGroup}>
          <label>Your Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={styles.textarea}
          ></textarea>
        </div>
        <button type="submit" style={styles.submitButton} disabled={isLoading}>
          {isLoading ? "Sending..." : "Send Message"}
        </button>
      </form>
    </section>
  );
};

// Inline styles
const styles = {
  container: {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    textAlign: "center",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  select: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  textarea: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    minHeight: "100px",
  },
  submitButton: {
    padding: "10px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default ContactForm;
