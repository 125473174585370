import React from "react";

const Pricing = () => {
  return (
    <section id="pricing" style={{ padding: "2rem", background: "#F3F1EF" }}>
      <h2 style={{ textAlign: "center", color: "#2959A5" }}>Pricing Plans</h2>
      <div style={{ display: "flex", justifyContent: "center", gap: "2rem" }}>
        <div style={{ padding: "1rem", border: "1px solid #2959A5", borderRadius: "5px", textAlign: "center" }}>
          <h3>Basic</h3>
          <p>$300/month</p>
        </div>
        <div style={{ padding: "1rem", border: "1px solid #2959A5", borderRadius: "5px", textAlign: "center" }}>
          <h3>Pro</h3>
          <p>$599/month</p>
        </div>
        <div style={{ padding: "1rem", border: "1px solid #2959A5", borderRadius: "5px", textAlign: "center" }}>
          <h3>Enterprise</h3>
          <p>Contact Us</p>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
