import React from "react";

const Features = () => {
  return (
    <section id="features" style={{ padding: "2rem", background: "#FFFFFF" }}>
      <h2 style={{ textAlign: "center", color: "#2959A5" }}>Features</h2>
      <ul style={{ maxWidth: "800px", margin: "0 auto", listStyle: "circle" }}>
        <li>Real-time Queue Management</li>
        <li>Seamless POS and Kiosk Integration</li>
        <li>Cloud-based Web Portal</li>
        <li>Customizable Branding Options</li>
      </ul>
    </section>
  );
};

export default Features;
